/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import './custom.scss';
import React from 'react';
import { FloatingWhatsApp } from 'react-floating-whatsapp';
import logo from './static/images/logo/logo-192.png';

export { wrapRootElement } from './src/hooks/useAuth';

// Wrap the root element with FloatingWhatsApp
export const wrapPageElement = ({ element }) => (
  <>
    {element}
    <FloatingWhatsApp
      phoneNumber="+525591866408" // Replace with your WhatsApp number
      accountName="Tu Experto de Pagos"
      avatar={logo}
      // chatMessage="¿Ayuda personalizado? Manda un mensaje y te asistimos a dentro de 55 segundos👍"
      chatMessage="¿Buscas ayuda personalizada?"
      statusMessage="Responde típicamente dentro de un minuto"
      placeholder="Escribe tu mensaje aquí..."
      darkMode={false}
      notification
      // notificationSound
      // notificationSoundSrc="https://example.com/notification-sound.mp3"
    />
  </>
);
